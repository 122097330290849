@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Font+Awesome+5+Free:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Catamaran:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;600&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-montserrat: Montserrat;
  --font-lato: Lato;
  --font-jost: Jost;
  --font-font-awesome-5-free: "Font Awesome 5 Free";
  --font-kumbh-sans: "Kumbh Sans";
  --font-open-sans: "Open Sans";
  --font-catamaran: Catamaran;
  --font-inter: Inter;
  --font-roboto: Roboto;

  /* font sizes */
  --font-size-3xs-5: 9.5px;
  --font-size-2xs-1: 10.1px;
  --font-size-xs-9: 11.9px;
  --font-size-7xs-5: 5.5px;
  --font-size-3xs: 10px;
  --font-size-lg: 18px;
  --font-size-mini: 15px;
  --font-size-sm-5: 13.5px;
  --font-size-3xs-7: 9.7px;
  --font-size-xs: 12px;
  --font-size-base-6: 16.6px;
  --font-size-11xl-1: 30.1px;
  --font-size-2xs-3: 10.3px;
  --font-size-sm-3: 13.3px;
  --font-size-xs-8: 11.8px;
  --font-size-2xs-7: 10.7px;
  --font-size-5xs: 8px;
  --font-size-mid-7: 17.7px;

  /* Colors */
  --color-white: #fff;
  --color-gray-100: #30283d;
  --color-gray-200: #222;
  --color-gray-300: #1c1e27;
  --color-gray-400: #0d0b0b;
  --color-darkorange: #ff932e;
  --color-darkslategray-100: #36474f;
  --color-darkslategray-200: #373f46;
  --color-darkslategray-300: rgba(54, 71, 79, 0.6);
  --color-darkslategray-400: rgba(54, 71, 79, 0.23);
  --color-ghostwhite: #e8e8ef;
  --color-slategray-100: #6f8197;
  --color-slategray-200: #757783;
  --color-whitesmoke: #f1f2f6;
  --color-darkgray-100: #a0a0a0;
  --color-darkgray-200: #949fa8;
  --color-firebrick: #cd4236;

  /* Paddings */
  --padding-sm-1: 13.1px;

  /* border radiuses */
  --br-4xs-9: 8.9px;
  --br-9xs-6: 3.6px;
  --br-xs-1: 11.1px;
  --br-7xs-6: 5.6px;
  --br-7xs: 6px;
  --br-8xs-3: 4.3px;
  --br-5xs-5: 7.5px;
  --br-10xs-2: 2.2px;
  --br-smi-2: 12.2px;
}
