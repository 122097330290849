.favicon-512x512-1 {
  position: absolute;
  top: 10px;
  left: 14px;
  width: 24px;
  height: 24px;
  object-fit: cover;
}

.ansoftt-pos,
.modern-system-and {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ansoftt-pos {
  top: 13.39px;
  left: 31.34px;
  letter-spacing: 0.05em;
  line-height: 19.16px;
  color: var(--color-darkslategray-100);
  text-align: center;
  width: 153.66px;
}

.modern-system-and {
  top: 35.69px;
  /* left: 22.25px; */
  line-height: 20.72px;
  font-weight: 300;
  width: 100%;
  height: 23.76px;
}

.heading-3 {
  position: absolute;
  top: -9.17px;
  left: 0;
  width: 297.14px;
  height: 71.76px;
}

.divscrolltop,
.list-item {
  position: absolute;
  top: 9.17px;
  left: 0;
  width: 297.06px;
  height: 47.53px;
  overflow: hidden;
}

.divscrolltop {
  top: 0.04px;
  width: 297.14px;
  height: 79.42px;
}

.enter-your-email {
  position: relative;
}

.divplaceholder {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 0 143.27256774902344px 0 0;
  align-items: flex-start;
  justify-content: flex-start;
}

.get-it-now,
.input {
  position: absolute;
  display: flex;
}

.input {
  top: 5.15px;
  left: calc(50% - 112.43px);
  border-radius: 12.19px;
  background-color: rgba(255, 255, 255, 0.95);
  flex-direction: row;
  padding: 5.179444313049316px 6.093463897705078px 5.48411750793457px;
  align-items: flex-start;
  justify-content: flex-start;
}

.get-it-now {
  top: 5.18px;
  left: 13.1px;
  line-height: 5.48px;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  width: 32.19px;
  height: 7.62px;
}

.form-form-fieldset,
.input1 {
  position: absolute;
  overflow: hidden;
}

.input1 {
  width: calc(100% - 164.62px);
  top: 5.15px;
  right: 4.91px;
  left: 159.71px;
  border-radius: 0 12.19px 12.19px 0;
  background-color: var(--color-darkorange);
  border: 0.9px solid var(--color-darkorange);
  box-sizing: border-box;
  height: 18.28px;
  text-align: center;
  color: var(--color-white);
}

.form-form-fieldset {
  top: 59.3px;
  left: calc(50% - 111.46px);
  border-radius: 16.13px;
  background-color: rgba(255, 255, 255, 0.2);
  width: 235.45px;
  height: 28.63px;
  display: none;
  text-align: left;
  font-size: 5.48px;
  color: #5c5c5c;
}

.imacpng-icon {
  position: absolute;
  top: calc(50% - 119.74px);
  left: calc(50% - 147.77px);
  width: 295.53px;
  height: 239.47px;
  object-fit: cover;
}

.divhome-slider,
.divwpb-wrapper {
  position: absolute;
  top: 105.11px;
  left: calc(50% - 148.55px);
  width: 297.06px;
  height: 239.47px;
}

.divwpb-wrapper {
  top: 48.75px;
  height: 92.93px;
}

.powerful-software-with {
  position: absolute;
  top: 61.32px;
  /* left: 181.59px; */
  line-height: 20.72px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 222.36px; */
  height: 24.37px;
}

.divhome-contain,
.section {
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.divhome-contain {
  top: 480px;
  left: 0;
  width: 584.97px;
  height: 294.31px;
  background-image: url(/public/img/Section_1.png);
}

/* .logo {
  height: 30px;
} */

.section {
  top: 50px;
  left: calc(50% - 292px);
  width: 585px;
  height: 279.8px;
  /* background-image: url(/public/section@3x.png); */
  text-align: center;
  font-size: 17.67px;
  color: var(--color-white);
  font-family: var(--font-open-sans);
}

.image-1-icon {
  position: absolute;
  top: calc(50% - 117.9px);
  left: calc(50% - 143.29px);
  border-radius: 8.64px;
  width: 286.07px;
  height: 265.31px;
  object-fit: cover;
}

.divimgholder {
  position: relative;
  width: 371.2px;
  height: 333.81px;
}

.strong-about {
  top: -8.01px;
  left: 0;
  line-height: 24.03px;
  font-weight: 600;
  width: 138.2px;
  height: 15.36px;
}

.our-mission-is,
.strong-about,
.we-can-help {
  position: absolute;
  display: flex;
  align-items: center;
}

.we-can-help {
  top: 19.36px;
  left: 0;
  font-size: 23.37px;
  line-height: 28.04px;
  font-weight: 600;
  font-family: var(--font-jost);
  color: #1c375b;
  width: 394.56px;
  height: 33.38px;
}

.our-mission-is {
  top: 24.7px;
  left: 2.67px;
  line-height: 16.02px;
  width: 362.95px;
  height: 28.71px;
}

.p,
.pseudo-icon {
  position: absolute;
  left: 0;
}

.p {
  top: 64.89px;
  width: 380.54px;
  height: 32.05px;
  font-size: 10.68px;
  color: var(--color-slategray-100);
  font-family: var(--font-roboto);
}

.pseudo-icon {
  top: 150.34px;
  width: 42.06px;
  height: 6.68px;
  object-fit: cover;
}

.header {
  position: absolute;
  top: 0.19px;
  left: calc(50% - 178.29px);
  width: 357px;
  height: 117px;
}

.clients-satisfaction,
.div {
  position: absolute;
  top: 4.01px;
  left: 9.35px;
  line-height: 8.01px;
  display: flex;
  align-items: center;
  width: 8.14px;
  height: 8.01px;
}

.clients-satisfaction {
  top: 1.88px;
  left: 23.37px;
  font-size: 10.68px;
  line-height: 16.02px;
  font-family: var(--font-roboto);
  color: var(--color-slategray-100);
  width: 103.48px;
  height: 12.68px;
}

.item {
  position: absolute;
  width: calc(100% - 200.71px);
  top: 0;
  right: 200.71px;
  left: 0;
  height: 16.02px;
}

.strategic-planning {
  position: absolute;
  top: 1.21px;
  left: 23.37px;
  font-size: 10.68px;
  line-height: 16.02px;
  font-family: var(--font-roboto);
  color: var(--color-slategray-100);
  display: flex;
  align-items: center;
  width: 152.22px;
  height: 12.68px;
}

.item1 {
  position: absolute;
  width: calc(100% - 200.71px);
  top: 0;
  right: 0.43px;
  left: 200.29px;
  height: 16.02px;
}

.innovative-solutions {
  position: absolute;
  top: 1.21px;
  left: 23.37px;
  font-size: 10.68px;
  line-height: 16.02px;
  font-family: var(--font-roboto);
  color: var(--color-slategray-100);
  display: flex;
  align-items: center;
  width: 116.83px;
  height: 12.68px;
}

.item2 {
  position: absolute;
  width: calc(100% - 200.71px);
  top: 23.37px;
  right: 200.71px;
  left: 0;
  height: 16.02px;
}

.continuous-improvement {
  position: absolute;
  top: 1.34px;
  left: 23.37px;
  font-size: 10.68px;
  line-height: 16.02px;
  font-family: var(--font-roboto);
  color: var(--color-slategray-100);
  display: flex;
  align-items: center;
  width: 124.62px;
  height: 12.68px;
}

.item3 {
  position: absolute;
  width: calc(100% - 200.71px);
  top: 23.37px;
  right: 0.43px;
  left: 200.29px;
  height: 16.02px;
}

.seamless-implementation {
  position: absolute;
  top: 1.21px;
  left: 23.37px;
  font-size: 10.68px;
  line-height: 16.02px;
  font-family: var(--font-roboto);
  color: var(--color-slategray-100);
  display: flex;
  align-items: center;
  width: 132.19px;
  height: 12.68px;
}

.item4 {
  position: absolute;
  width: calc(100% - 200.71px);
  top: 46.73px;
  right: 200.71px;
  left: 0;
  height: 16.02px;
}

.ongoing-support {
  top: 1.34px;
  left: 23.37px;
  font-size: 10.68px;
  line-height: 16.02px;
  font-family: var(--font-roboto);
  color: var(--color-slategray-100);
  display: flex;
  align-items: center;
  width: 89.37px;
  height: 12.68px;
}

.item5,
.list,
.ongoing-support {
  position: absolute;
}

.item5 {
  width: calc(100% - 200.71px);
  top: 46.73px;
  right: 0.43px;
  left: 200.29px;
  height: 16.02px;
}

.list {
  top: 181.19px;
  left: calc(50% - 174.29px);
  width: 348px;
  height: 70px;
  font-size: 8.01px;
  color: var(--color-firebrick);
  font-family: var(--font-font-awesome-5-free);
}

.divcol-12 {
  position: relative;
  width: 401px;
  height: 275px;
}

.article,
.streamline-your-business {
  position: absolute;
  display: flex;
  align-items: center;
}

.article {
  top: 950px;
  left: calc(50% - 200.21px);
  flex-direction: column;
  justify-content: center;
  gap: 50px;
  font-size: 18.03px;
}

.streamline-your-business {
  top: -13.82px;
  left: 0;
  line-height: 24.72px;
  font-weight: 600;
  width: 373px;
  height: 35px;
}

.heading-4,
.our-advanced-point {
  position: absolute;
  top: 30.82px;
  left: 0;
  width: 266px;
  height: 38px;
}

.our-advanced-point {
  top: -4px;
  line-height: 14.01px;
  font-weight: 300;
  display: flex;
  align-items: center;
  width: 345px;
  height: 37px;
}

.p1,
.strong-about1 {
  position: absolute;
  top: 76px;
  left: 0;
  width: 349px;
  height: 42px;
  font-size: 6.54px;
  font-family: var(--font-open-sans);
}

.strong-about1 {
  top: -17.65px;
  left: 1.77px;
  font-size: 15.89px;
  line-height: 21.19px;
  font-weight: 600;
  font-family: var(--font-montserrat);
  color: var(--color-darkorange);
  display: flex;
  align-items: center;
  width: 121.82px;
  height: 13.54px;
}

.divtxt-holder {
  position: absolute;
  margin: 0 !important;
  top: 25.39px;
  left: calc(50% - 178.14px);
  width: 308px;
  height: 195px;
  z-index: 0;
}

.img03png-icon {
  position: relative;
  width: 210.9px;
  height: 150.99px;
  object-fit: cover;
  z-index: 1;
}

.divrow {
  display: flex;
  flex-direction: column;
  padding: 152px 0 0;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  gap: 180px;
}

.how-it-works,
.section1 {
  position: absolute;
  display: flex;
  align-items: center;
}

.section1 {
  top: 1610px;
  left: calc(50% - 233px);
  flex-direction: column;
  padding: 36.610103607177734px 127.68889617919922px;
  justify-content: flex-start;
  background-image: url(/public/img/Section_2.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  font-size: 20.6px;
  color: var(--color-white);
  font-family: var(--font-jost);
}

.how-it-works {
  top: -2.45px;
  left: calc(50% - 52.49px);
  line-height: 26.55px;
  font-weight: 600;
  width: 104px;
  height: 31px;
}

.heading-41 {
  position: absolute;
  top: 0;
  left: 11.06px;
  width: 862.86px;
  height: 26.55px;
}

.discover-the-power {
  position: absolute;
  top: 2.95px;
  left: 262.88px;
  line-height: 20.65px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 350.77px;
  height: 34.66px;
}

.p2 {
  position: absolute;
  top: 34.94px;
  left: 11.06px;
  width: 862.86px;
  height: 41.3px;
  text-align: center;
  font-size: 10.32px;
  color: #8b939b;
  font-family: var(--font-open-sans);
}

.header1 {
  width: calc(100% - 0.02px);
  top: 29.45px;
  right: 0.02px;
  left: 0;
  height: 89.24px;
}

.div6,
.header1,
.spannum {
  position: absolute;
}

.div6 {
  top: 8.85px;
  left: 13.6px;
  line-height: 14.75px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.65px;
  height: 14.01px;
}

.spannum {
  top: 0;
  left: calc(50% - 15.86px);
  border-radius: 2.21px;
  background-color: var(--color-darkslategray-200);
  width: 31.71px;
  height: 31.71px;
}

.icon04png {
  position: absolute;
  top: 64.16px;
  left: 124.25px;
  width: 39.09px;
  height: 39.09px;
}

.customers-choose-products,
.heading-31 {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heading-31 {
  top: 128.41px;
  left: calc(50% - 129.52px);
  font-size: 13.27px;
  line-height: 14.75px;
  font-weight: 600;
  color: var(--color-darkslategray-200);
  width: 260px;
  height: 16px;
}

.customers-choose-products {
  top: 3.69px;
  left: calc(50% - 123.5px);
  line-height: 19.17px;
  font-weight: 300;
  width: 247.8px;
  height: 33.19px;
}

.p3 {
  position: absolute;
  top: 154.41px;
  left: calc(50% - 158.52px);
  width: 317px;
  height: 39px;
  font-size: 10.32px;
  color: var(--color-darkgray-200);
  font-family: var(--font-open-sans);
}

.product-1-icon {
  position: absolute;
  top: 59.74px;
  left: 122.42px;
  width: 44.25px;
  height: 44.25px;
  overflow: hidden;
}

.item6 {
  position: relative;
  width: 287.58px;
  height: 202.42px;
}

.div7,
.spannum1 {
  position: absolute;
}

.div7 {
  top: 8.85px;
  left: 12.43px;
  line-height: 14.75px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7.01px;
  height: 14.01px;
}

.spannum1 {
  top: 0.42px;
  left: calc(50% - 15.86px);
  border-radius: 2.21px;
  background-color: var(--color-darkslategray-200);
  width: 31.71px;
  height: 31.71px;
}

.customers-provide-payment,
.heading-32 {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heading-32 {
  top: 131px;
  left: calc(50% - 85px);
  font-size: 13.27px;
  line-height: 14.75px;
  font-weight: 600;
  color: var(--color-darkslategray-200);
  width: 171px;
  height: 16px;
}

.customers-provide-payment {
  top: 10.32px;
  left: -12.97px;
  line-height: 19.17px;
  font-weight: 300;
  width: 283.93px;
  height: 33.19px;
}

.p4 {
  position: absolute;
  top: 157.5px;
  left: 14.96px;
  width: 258.08px;
  height: 38.33px;
  font-size: 10.32px;
  color: var(--color-darkgray-200);
  font-family: var(--font-open-sans);
}

.payment-system-1-icon {
  position: absolute;
  top: 60.15px;
  left: 122.2px;
  width: 44.25px;
  height: 44.25px;
  overflow: hidden;
}

.item7 {
  position: relative;
  width: 288px;
  height: 224px;
}

.div8,
.spannum2 {
  position: absolute;
}

.div8 {
  top: 8.85px;
  left: 12.42px;
  line-height: 14.75px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7.01px;
  height: 14.01px;
}

.spannum2 {
  top: 0;
  left: calc(50% - 15.74px);
  border-radius: 2.21px;
  background-color: var(--color-darkslategray-200);
  width: 31.71px;
  height: 31.71px;
}

.icon06png {
  position: absolute;
  top: 64.16px;
  left: 124.38px;
  width: 39.09px;
  height: 39.09px;
}

.after-successful-payment,
.heading-33 {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heading-33 {
  top: 122.58px;
  left: calc(50% - 141px);
  font-size: 13.27px;
  line-height: 14.75px;
  font-weight: 600;
  color: var(--color-darkslategray-200);
  width: 281px;
  height: 17px;
}

.after-successful-payment {
  top: 2.21px;
  left: -0.14px;
  line-height: 19.17px;
  font-weight: 300;
  width: 258.12px;
  height: 33.19px;
}

.group-icon,
.p5 {
  position: absolute;
}

.p5 {
  top: 158.46px;
  left: 14.89px;
  width: 258.08px;
  height: 38.33px;
  font-size: 10.32px;
  color: var(--color-darkgray-200);
  font-family: var(--font-open-sans);
}

.group-icon {
  height: 19.17%;
  width: 13.28%;
  top: 25.88%;
  right: 43.13%;
  bottom: 54.95%;
  left: 43.59%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.item8 {
  position: relative;
  width: 287.62px;
  height: 230.83px;
}

.list1,
.pseudo {
  position: absolute;
}

.list1 {
  top: 159px;
  left: calc(50% - 192.5px);
  width: 386px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 25px;
  text-align: center;
  font-size: var(--font-size-xs-8);
  color: var(--color-white);
}

.pseudo {
  top: 128px;
  left: 151px;
  border: 0.7px dashed #d7d9da;
  box-sizing: border-box;
  width: 582.61px;
  height: 1.47px;
}

.divprocess-holder {
  position: absolute;
  top: 1990px;
  left: calc(50% - 443px);
  width: 885px;
  height: 445px;
}

.divelementor-background-overl-icon {
  position: absolute;
  height: calc(100% + 528.21px);
  width: calc(100% + 979.71px);
  top: -72px;
  right: -1375.71px;
  bottom: -456.21px;
  left: 396px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  opacity: 0.8;
}

.div9 {
  top: 29.35px;
  left: 30.1px;
  line-height: 30.1px;
  width: 30.25px;
  height: 30.85px;
}

.div9,
.effortlessly-track-and,
.heading-42 {
  position: absolute;
  display: flex;
  align-items: center;
}

.heading-42 {
  top: -3.51px;
  left: -0.05px;
  line-height: 19.87px;
  font-weight: 500;
  width: 186px;
  height: 27px;
}

.effortlessly-track-and {
  top: -1.51px;
  left: 0;
  line-height: 18.06px;
  width: 203.31px;
  height: 56.44px;
}

.divicon-box,
.picon-box-description {
  position: absolute;
  top: 27.38px;
  left: 0;
  width: 214.46px;
  height: 54.18px;
  font-size: 12.04px;
  color: var(--color-darkgray-100);
}

.divicon-box {
  top: 75.25px;
  left: 30.1px;
  height: 81.57px;
  font-size: 16.56px;
  color: var(--color-gray-400);
  font-family: var(--font-catamaran);
}

.inspection-1-icon {
  position: absolute;
  top: 22.75px;
  left: 30.05px;
  width: 40px;
  height: 40px;
  overflow: hidden;
}

.divjkit-icon-box-wrapper {
  position: absolute;
  top: 61px;
  left: calc(50% - 137.15px);
  border-radius: 7.53px;
  background-color: var(--color-white);
  box-shadow: 0 2.2576003074645996px 11.29px rgba(0, 0, 0, 0.1);
  width: 274.66px;
  height: 186.92px;
  overflow: hidden;
}

.build-strong-customer,
.heading-5 {
  position: absolute;
  display: flex;
  align-items: center;
}

.heading-5 {
  top: -4px;
  left: -0.05px;
  line-height: 19.87px;
  font-weight: 500;
  width: 186px;
  height: 27px;
}

.build-strong-customer {
  top: 5.5px;
  left: 0;
  line-height: 18.06px;
  width: 203.31px;
  height: 56.44px;
}

.divicon-box1,
.picon-box-description1 {
  position: absolute;
  top: 27.38px;
  left: 0;
  width: 214.46px;
  height: 54.18px;
  font-size: 12.04px;
}

.divicon-box1 {
  top: 75.25px;
  left: 30.1px;
  height: 81.57px;
  font-size: 16.56px;
  font-family: var(--font-catamaran);
}

.customer-care-1-icon,
.divjkit-icon-box-wrapper1 {
  position: absolute;
  top: 18.25px;
  left: 30.05px;
  width: 40px;
  height: 40px;
  overflow: hidden;
}

.divjkit-icon-box-wrapper1 {
  top: 268px;
  left: 474px;
  border-radius: 7.53px;
  background-color: var(--color-darkslategray-100);
  box-shadow: 0 0 11.29px rgba(0, 0, 0, 0.1);
  width: 274.66px;
  height: 186.92px;
  color: var(--color-white);
}

.gain-valuable-insights,
.heading-51 {
  position: absolute;
  display: flex;
  align-items: center;
}

.heading-51 {
  top: -3.5px;
  left: -0.05px;
  line-height: 19.87px;
  font-weight: 500;
  width: 175px;
  height: 27px;
}

.gain-valuable-insights {
  top: 4.49px;
  left: 0;
  line-height: 18.06px;
  width: 203.31px;
  height: 56.44px;
}

.divicon-box2,
.report-3-1 {
  position: absolute;
  top: 75.25px;
  left: 30.1px;
  width: 214.46px;
  height: 81.57px;
}

.report-3-1 {
  top: 16.75px;
  left: 30.05px;
  width: 40px;
  height: 40px;
  overflow: hidden;
}

.divjkit-icon-box-wrapper2,
.section2 {
  position: absolute;
  background-color: var(--color-white);
}

.divjkit-icon-box-wrapper2 {
  top: 475px;
  left: calc(50% - 137.15px);
  border-radius: 7.53px;
  box-shadow: 0 2.2576003074645996px 11.29px rgba(0, 0, 0, 0.1);
  width: 274.66px;
  height: 186.92px;
  overflow: hidden;
  font-size: 16.56px;
  color: var(--color-gray-400);
  font-family: var(--font-catamaran);
}

.section2 {
  top: 2940px;
  left: calc(50% - 611px);
  width: 1222.29px;
  height: 659px;
  font-size: var(--font-size-11xl-1);
  color: var(--color-darkslategray-100);
  font-family: var(--font-inter);
}

.enjoy-our-special,
.heading-52 {
  position: absolute;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.heading-52 {
  top: 107.24px;
  left: calc(50% - 49.5px);
  line-height: 20.07px;
  text-align: center;
  justify-content: center;
  width: 99.78px;
  height: 11.71px;
}

.enjoy-our-special {
  top: -4px;
  left: calc(50% - 120px);
  line-height: 23.41px;
  width: 241px;
  height: 50px;
}

.heading-2 {
  position: absolute;
  top: 126px;
  left: calc(50% - 135.5px);
  width: 272px;
  height: 42px;
  font-size: 19.51px;
  color: var(--color-gray-400);
  font-family: var(--font-jost);
}

.unlock-an-exceptional {
  position: absolute;
  top: -17px;
  left: calc(50% - 130px);
  line-height: 10.07px;
  display: flex;
  align-items: center;
  width: 260px;
  height: 22px;
}

.divelementor-widget-container {
  position: absolute;
  top: 178px;
  left: calc(50% - 111.5px);
  width: 224px;
  height: 20px;
  font-size: var(--font-size-3xs);
  color: var(--color-darkgray-100);
  font-family: var(--font-catamaran);
}

.divelementor-widget-wrap {
  position: absolute;
  top: 2790px;
  left: calc(50% - 75px);
  width: 151px;
  height: 350.33px;
  font-size: 15.05px;
}

.heading-53 {
  position: absolute;
  top: -4.24px;
  left: -0.29px;
  line-height: 19.87px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 204px;
  height: 28px;
}

.picon-box-description3 {
  position: absolute;
  top: 27.39px;
  left: 0;
  width: 214.46px;
  height: 54.18px;
  font-size: 12.04px;
  color: var(--color-darkgray-100);
}

.basket-1-icon {
  position: absolute;
  top: 24.01px;
  left: 29.81px;
  width: 40px;
  height: 40px;
  overflow: hidden;
}

.divjkit-icon-box-wrapper3 {
  position: absolute;
  top: 3620px;
  left: calc(50% - 137px);
  border-radius: 7.53px;
  background-color: var(--color-white);
  box-shadow: 0 2.2576003074645996px 11.29px rgba(0, 0, 0, 0.1);
  width: 274.66px;
  height: 186.92px;
  overflow: hidden;
  font-size: 16.56px;
  color: var(--color-gray-400);
  font-family: var(--font-catamaran);
}

.heading-54 {
  position: absolute;
  top: -3.76px;
  left: 0;
  line-height: 19.87px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 184.44px;
  height: 27.09px;
}

.shops-1-icon {
  position: absolute;
  top: 17.5px;
  left: 29.81px;
  width: 40px;
  height: 40px;
  overflow: hidden;
}

.divjkit-icon-box-wrapper4 {
  position: absolute;
  top: 3820px;
  left: calc(50% - 137px);
  border-radius: 7.53px;
  background-color: var(--color-white);
  box-shadow: 0 2.2576003074645996px 11.29px rgba(0, 0, 0, 0.1);
  width: 274.66px;
  height: 186.92px;
  overflow: hidden;
  font-size: 16.56px;
  color: var(--color-gray-400);
  font-family: var(--font-catamaran);
}

.image-2-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 407.6px;
  height: 264.53px;
  object-fit: cover;
}

.were-the-best {
  position: absolute;
  top: 12.39px;
  left: 51.17px;
  letter-spacing: 0.75px;
  line-height: 12.93px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  width: 130px;
  height: 9.69px;
}

.divwhy-choose-one-right-img- {
  position: absolute;
  height: calc(100% - 256px);
  width: calc(100% - 84px);
  top: 0;
  right: 44px;
  bottom: 256px;
  left: 40px;
  border-radius: 4.31px 4.31px 0 0;
  background-color: var(--color-darkslategray-100);
  transform: rotate(90deg);
  transform-origin: 0 0;
}

.divwhy-choose-one-right-img {
  position: relative;
  width: 348px;
  height: 296px;
  overflow: hidden;
  flex-shrink: 0;
}

.choose-us-as {
  position: absolute;
  top: -52px;
  left: 0;
  line-height: 17.24px;
  display: flex;
  align-items: center;
  width: 348px;
  height: 47px;
}

.benefits-of-product,
.pwhy-choose-one-left-text {
  position: absolute;
  top: 105px;
  left: 0;
  width: 348px;
  height: 52px;
}

.benefits-of-product {
  top: -10.11px;
  line-height: 27.99px;
  text-transform: capitalize;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 350px;
  height: 65px;
}

.heading-21,
.why-choose-us {
  position: absolute;
  top: 18.44px;
  left: 0.08px;
  width: 263.92px;
  height: 64.63px;
}

.why-choose-us {
  top: -2.44px;
  left: 0.09px;
  font-size: var(--font-size-mini);
  line-height: 23.99px;
  font-weight: 600;
  font-family: var(--font-montserrat);
  color: var(--color-darkorange);
  display: flex;
  align-items: center;
  width: 176px;
  height: 20px;
}

.divsection-title {
  position: absolute;
  top: -5.56px;
  left: -0.08px;
  width: 263.86px;
  height: 91.95px;
  font-size: var(--font-size-lg);
  color: var(--color-gray-300);
  font-family: var(--font-jost);
}

.icon {
  position: absolute;
  top: -65px;
  left: 0;
  width: 35.01px;
  height: 35.01px;
  overflow: hidden;
}

.trusted-and-reliable {
  position: relative;
  line-height: 13.33px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 293px;
  flex-shrink: 0;
}

.heading-43 {
  position: absolute;
  top: -52.2px;
  left: 47px;
  width: 300px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.divwhy-choose-one-left-learn {
  position: absolute;
  top: 177.2px;
  left: 0;
  width: 263.92px;
  height: 38.24px;
  font-size: 9.99px;
  color: var(--color-gray-300);
  font-family: var(--font-montserrat);
}

.icon1 {
  position: absolute;
  top: 1.53px;
  left: 0;
  width: 10.77px;
  height: 10.77px;
  overflow: hidden;
}

.trusted-expertise {
  position: absolute;
  top: 2.07px;
  left: 18.85px;
  line-height: 17.24px;
  display: flex;
  align-items: center;
  width: 179.84px;
  height: 12.39px;
}

.icon2,
.item9 {
  position: absolute;
  top: 0.09px;
  left: 0;
  width: 130px;
  height: 17px;
}

.icon2 {
  top: 1.62px;
  width: 10.77px;
  height: 10.77px;
  overflow: hidden;
}

.seamless-integration {
  position: absolute;
  top: 2.16px;
  left: 18.85px;
  line-height: 17.24px;
  display: flex;
  align-items: center;
  width: 146.14px;
  height: 12.39px;
}

.icon3,
.item10,
.list2 {
  position: absolute;
  top: 20.09px;
  left: 0;
  width: 130px;
  height: 17px;
}

.icon3,
.list2 {
  top: 165.91px;
  width: 263.92px;
  height: 60.32px;
  overflow: hidden;
}

.icon3 {
  top: 2.07px;
  width: 10.77px;
  height: 10.77px;
}

.innovative-solutions1 {
  position: absolute;
  top: 2.61px;
  left: 18.85px;
  line-height: 17.24px;
  display: flex;
  align-items: center;
  width: 174.41px;
  height: 12.39px;
}

.item11 {
  position: absolute;
  top: 165px;
  left: 220px;
  width: 130px;
  height: 18px;
}

.divwhy-choose-one-left {
  position: relative;
  width: 307px;
  height: 296px;
  font-size: 9.69px;
  color: var(--color-slategray-200);
}

.divrow1 {
  position: absolute;
  top: 4080px;
  left: calc(50% - 179px);
  width: 358px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0 7.995873928070068px;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  font-size: 7.54px;
  color: var(--color-white);
  font-family: var(--font-kumbh-sans);
}

.divsection-title1 {
  position: absolute;
  top: 0;
  left: 3.36px;
  width: 329.22px;
  height: 22.4px;
}

.testimonials-one-left-pattern-icon,
.thm-shape3png-icon {
  position: absolute;
  top: calc(50% - 96.17px);
  object-fit: cover;
}

.testimonials-one-left-pattern-icon {
  left: -50.39px;
  width: 185.89px;
  height: 181.41px;
  opacity: 0.15;
}

.thm-shape3png-icon {
  left: 272.11px;
  width: 129.9px;
  height: 129.9px;
}

.divtestimonials-one-wrapper {
  position: absolute;
  top: 23.16px;
  left: 3.36px;
  width: 329.22px;
  height: 95.41px;
}

.divauto-container,
.our-product-usp {
  position: absolute;
  top: 25.31px;
  left: calc(50% - 167.97px);
  width: 335.94px;
  height: 127.21px;
}

.our-product-usp {
  top: 33px;
  left: calc(50% - 82px);
  line-height: 10.73px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 165px;
  height: 9px;
}

.section3 {
  position: absolute;
  top: 4710px;
  left: 0;
  background-color: var(--color-darkslategray-100);
  width: 430px;
  height: 196px;
  overflow: hidden;
  text-align: center;
}

.testimonials-one-left-pattern-icon1 {
  position: absolute;
  top: calc(50% - 743.15px);
  left: 333px;
  width: 623.8px;
  height: 608.77px;
  object-fit: cover;
  opacity: 0.15;
}

.as-your-business,
.heading-44 {
  /* position: absolute;
  top: -36.92px; */
  /* left: 47.9px; */
  margin-top: 10px;
  margin-left: 10px;
  line-height: 21.04px;
  display: flex;
  align-items: center;
  /* width: 199px; */
  height: 18px;
}

.as-your-business {
  /* top: -58.99px; */
  left: -2px;
  line-height: 24.05px;
  font-weight: 300;
  width: 276.39px;
  height: 130.44px;
}

.ptestimonials-one-single-tex {
  /* position: absolute;
  top: 72.15px; */
  /* left: 38.33px; */
  width: 276.58px;
  height: 96.2px;
  color: var(--color-slategray-200);
}

.divtestimonials-one-single-i {
  position: relative;
  /* top: -68px; */
  /* left: 10.91px; */
  border-radius: 6.01px;
  background-color: var(--color-white);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25),
    0 7.515625476837158px 45.09px rgba(0, 0, 0, 0.07);
  width: 353px;
  height: 210px;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.heading-45,
.our-pos-system {
  position: absolute;
  top: 22.08px;
  left: 37.9px;
  line-height: 21.04px;
  display: flex;
  align-items: center;
  width: 175px;
  height: 18px;
}

.our-pos-system {
  top: -3.99px;
  left: 0;
  line-height: 20px;
  font-weight: 300;
  width: 276.39px;
  height: 89.44px;
}

.ptestimonials-one-single-tex1 {
  position: absolute;
  top: 59.15px;
  left: 38.11px;
  width: 276.58px;
  height: 96.2px;
  font-size: var(--font-size-3xs);
  color: var(--color-slategray-200);
}

.divtestimonials-one-single-i1 {
  position: absolute;
  top: -72px;
  left: 2252.91px;
  border-radius: 6.01px;
  background-color: var(--color-white);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25),
    0 7.515625476837158px 45.09px rgba(0, 0, 0, 0.07);
  width: 353px;
  height: 168px;
}

.gain-valuable-insights1,
.heading-46 {
  position: absolute;
  top: 35.08px;
  left: 37.9px;
  line-height: 21.04px;
  display: flex;
  align-items: center;
  width: 266px;
  height: 18px;
}

.gain-valuable-insights1 {
  top: 46.01px;
  left: 0;
  line-height: 24.05px;
  font-weight: 300;
  width: 276.39px;
  height: 89.44px;
}

.ptestimonials-one-single-tex2 {
  position: absolute;
  top: 72.15px;
  left: 37.89px;
  width: 276.58px;
  height: 96.2px;
  color: var(--color-slategray-200);
}

.divtestimonials-one-single-i2 {
  position: absolute;
  top: -72px;
  left: 2630.91px;
  border-radius: 6.01px;
  background-color: var(--color-white);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25),
    0 7.515625476837158px 45.09px rgba(0, 0, 0, 0.07);
  width: 353px;
  height: 168px;
}

.divowl-stage {
  position: relative;
  top: 0;
  /* left: -1878.91px; */
  width: 1200px;
  height: 263.8px;
  display: flex;
  flex-direction: row;
  /* overflow-y: scroll; */
}

.button,
.button1,
.button2 {
  position: absolute;
  top: -141.91px;
  left: calc(50% - 24.43px);
  border-radius: 5.64px;
  background-color: var(--color-darkslategray-100);
  width: 11.27px;
  height: 11.27px;
}

.button1,
.button2 {
  left: calc(50% - 5.64px);
  background-color: var(--color-whitesmoke);
}

.button2 {
  left: calc(50% + 13.15px);
}

.divowl-dots {
  position: absolute;
  top: 263.8px;
  left: 0;
  width: 1104.8px;
  height: 56.37px;
}

.divtestimonials-one-carousel {
  position: relative;
  width: 100%;
  top: -60px;
  right: 0;
  left: 0;
  height: 320.17px;
  /* overflowY: 'scroll'; */
  /* overflow: auto;
  white-space: nowrap; */
}

.divtestimonials-one-wrapper1 {
  position: absolute;
  top: 4840px;
  /* left: -333px; */
  width: 400.8px;
  height: 320.17px;
  font-size: 13.53px;
  color: var(--color-darkslategray-100);
  font-family: var(--font-kumbh-sans);
}

.logo-ipsum-white01png-icon,
.logo-ipsum-white02png-icon,
.logo-ipsum-white03png-icon,
.logo-ipsum-white04png-icon {
  position: absolute;
  top: calc(50% - 16.69px);
  left: calc(50% - 192.78px);
  width: 90.72px;
  height: 33.38px;
  object-fit: cover;
  opacity: 0.5;
}

.logo-ipsum-white02png-icon,
.logo-ipsum-white03png-icon,
.logo-ipsum-white04png-icon {
  left: calc(50% - 94.5px);
}

.logo-ipsum-white03png-icon,
.logo-ipsum-white04png-icon {
  left: calc(50% + 3.78px);
}

.logo-ipsum-white04png-icon {
  left: calc(50% + 102.06px);
}

.section5 {
  position: absolute;
  width: calc(100% - 196.88px);
  top: 30.85px;
  right: 171px;
  left: 25.88px;
  height: 40.94px;
}

.section4 {
  position: absolute;
  top: 4499px;
  left: calc(50% - 222px);
  border-radius: 7.76px;
  background-color: var(--color-darkslategray-100);
  box-shadow: 0 2.0701754093170166px 2.07px rgba(0, 0, 0, 0.25),
    0 12.938596725463867px 38.82px rgba(0, 20, 91, 0.16);
  width: 590px;
  height: 102.63px;
}

.frequently-asked-questions {
  position: absolute;
  top: -6.98px;
  left: 4px;
  /* left:  calc(50% - 108.94px); */
  line-height: 15.7px;
  font-weight: 600;
  align-items: center;
}

.have-any-questions {
  position: absolute;
  top: 26px;
  left: -7.26px;
  font-size: var(--font-size-lg);
  line-height: 15.7px;
  font-family: var(--font-open-sans);
  color: var(--color-gray-200);
  align-items: center;
}

.heading-34 {
  position: absolute;
  top: 12.64px;
  left: calc(50% - 87.2px);
  /* border-bottom: 0.3px solid var(--color-gray-200); */
  box-sizing: border-box;
  width: 174.27px;
  height: 39.89px;
}

.pseudo1 {
  position: absolute;
  top: -0.39px;
  left: 0;
  background: linear-gradient(90deg, #ff932e, #36474f);
  width: 378.46px;
  height: 88.18px;
}

/* .yes-absolutely-ansoftt {
  position: absolute;
  top: 37.77px;
  left: 23.8px;
  line-height: 13.27px;
  font-weight: 500;
  width: 294.18px;
  height: 35.11px;
} */

/* .div16,
.is-ansoftt-pos,
.yes-absolutely-ansoftt {
  display: flex;
  align-items: center;
}

.is-ansoftt-pos {
  position: absolute;
  top: -0.29px;
  left: 23.22px;
  line-height: 9.36px;
  font-weight: 600;
  width: 285px;
  height: 10px;
} */

.div16 {
  top: 0;
  left: 0;
  line-height: 13.27px;
  justify-content: center;
  width: 22.32px;
  height: 22.24px;
}

.div15,
.div16,
.pseudo2 {
  position: absolute;
}

.pseudo2 {
  top: -6.44px;
  left: 331.25px;
  border-radius: 11.12px;
  background-color: var(--color-darkorange);
  width: 22.24px;
  height: 22.24px;
  text-align: center;
  font-size: 5.46px;
  color: var(--color-white);
  font-family: var(--font-font-awesome-5-free);
}

.div15 {
  top: 12.29px;
  left: 0.39px;
  width: 376.9px;
  height: 9.36px;
  font-size: var(--font-size-3xs);
  color: var(--color-darkslategray-200);
  font-family: var(--font-montserrat);
}

.div13,
.div14 {
  position: absolute;
  top: 0;
  height: 87.4px;
}

.div14 {
  left: 0.39px;
  background-color: var(--color-white);
  border: 0.4px solid var(--color-ghostwhite);
  box-sizing: border-box;
  width: 377.68px;
}

.div13 {
  left: 0;
  background-color: transparent;
  box-shadow: 0 0 11.7px rgba(0, 0, 0, 0.04);
  width: 378.46px;
  font-size: 7.02px;
  color: #7c7d8a;
  font-family: var(--font-kumbh-sans);
}

.can-ansoftt-pos {
  position: absolute;
  top: 0.51px;
  left: 23.61px;
  line-height: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 322px;
  height: 9px;
}

.pseudo3 {
  position: absolute;
  top: -6.44px;
  left: 332.03px;
  border-radius: 11.12px;
  background-color: var(--color-darkslategray-100);
  width: 22.24px;
  height: 22.24px;
  text-align: center;
  font-size: 5.46px;
  color: var(--color-white);
  font-family: var(--font-font-awesome-5-free);
}

.div17,
.div18 {
  position: absolute;
  top: 12.29px;
  left: 0.39px;
  width: 377.68px;
  height: 9.36px;
}

.div17 {
  top: 95.2px;
  left: 0;
  background-color: var(--color-white);
  border: 0.4px solid var(--color-ghostwhite);
  box-sizing: border-box;
  width: 378.46px;
  height: 33.94px;
}

.how-easy-is {
  top: -0.24px;
  left: 23.61px;
  line-height: 9.36px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 283px;
  height: 10px;
}

.div20,
.how-easy-is,
.pseudo4 {
  position: absolute;
}

.pseudo6 {
  position: absolute;
}

/* .pseudo4 {
  top: -6.44px;
  left: 332.03px;
  border-radius: 11.12px;
  background-color: var(--color-darkslategray-200);
  width: 22.24px;
  height: 22.24px;
  text-align: center;
  font-size: 5.46px;
  color: var(--color-white);
  font-family: var(--font-font-awesome-5-free);
} */

.div20 {
  top: 136.95px;
  left: 0;
  background-color: var(--color-white);
  border: 0.4px solid var(--color-ghostwhite);
  box-sizing: border-box;
  width: 378.46px;
  height: 33.94px;
}

.what-type-of {
  position: absolute;
  top: 0.12px;
  left: 23.41px;
  line-height: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 299.26px;
  height: 9.36px;
}

.div23 {
  top: 178.7px;
  left: 0;
  background-color: var(--color-white);
  border: 0.4px solid var(--color-ghostwhite);
  box-sizing: border-box;
  width: 378.46px;
  height: 33.94px;
}

.div11,
.div12,
.div23 {
  position: absolute;
}

.div12 {
  top: 80.54px;
  left: calc(50% - 189px);
  width: 378.46px;
  height: 212.64px;
  text-align: left;
  font-size: var(--font-size-3xs);
  color: var(--color-gray-100);
}


/* .div12-2 {
  position: absolute;
}

.div12-2 {
  top: 80.54px;
  left: calc(50% - 189px);
  width: 378.46px;
  height: 112.64px;
  text-align: left;
  font-size: var(--font-size-3xs);
  color: var(--color-gray-100);
} */

.div11 {
  top: 5100px;
  left: calc(50% - 218px);
  width: 436px;
  height: 322.2px;
  text-align: center;
}

.divelementor-background-overl {
  position: absolute;
  top: calc(50% - 480.5px);
  left: calc(50% - 247.17px);
  background-color: var(--color-darkslategray-100);
  width: 496.27px;
  height: 955.92px;
  opacity: 0.6;
}

.section6 {
  position: absolute;
  top: calc(50% - 445px);
  left: calc(50% - 195px);
  width: 391px;
  height: 888px;
  background-image: url(/public/section2@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.administrations {
  position: absolute;
  top: 87.44px;
  left: 349.56px;
}

.employee-management {
  position: absolute;
  top: 130.2px;
  left: 89.94px;
}

.purchasing-operations {
  position: absolute;
  top: 223.72px;
  left: 80.99px;
}

.stock-management {
  position: absolute;
  top: 313.25px;
  left: 105.83px;
}

.ansoftt-pos1,
.pos-report,
.pos-sales-counter {
  position: absolute;
  top: 180.97px;
  left: 349.56px;
}

.ansoftt-pos1,
.pos-report {
  top: 271.46px;
  font-weight: 900;
}

.ansoftt-pos1 {
  top: 9px;
  left: 106px;
  letter-spacing: 0.1em;
  line-height: 34.82px;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 279.25px;
}

.favicon-512x512-11 {
  position: absolute;
  top: 0;
  left: 101px;
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.layer-2-icon {
  position: absolute;
  height: 82.59%;
  width: 100%;
  top: 17.41%;
  right: -10.77%;
  bottom: 0;
  left: 10.77%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.ansoftt-pos-parent {
  text-align: center;
  font-size: 20px;
}

.administrations-parent,
.ansoftt-pos-parent,
.group {
  position: absolute;
  top: 0;
  left: 0;
  width: 548px;
  height: 361.79px;
}

.group {
  top: 39px;
  left: -59px;
}

.heading-47,
.label-name {
  position: absolute;
  top: 26.21px;
  left: 29.79px;
  line-height: 25.02px;
  display: flex;
  align-items: center;
  width: 148.72px;
  height: 20.26px;
}

.label-name {
  top: -1.19px;
  left: 7.75px;
  line-height: 9.53px;
  font-weight: 600;
  width: 25.44px;
  height: 11.32px;
}

.divplaceholder1,
.name {
  position: absolute;
  height: 12.51px;
}

.name {
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 26.54px;
}

.divplaceholder1 {
  top: 7.51px;
  left: 10.13px;
  width: 112.01px;
  overflow: hidden;
  opacity: 0.6;
}

.input2 {
  position: absolute;
  width: calc(100% - 15.49px);
  top: calc(50% - 5.06px);
  right: 7.75px;
  left: 7.75px;
  border-radius: 3.57px;
  background-color: var(--color-white);
  border: 0.6px solid var(--color-darkslategray-400);
  box-sizing: border-box;
  height: 28px;
  font-size: 10.13px;
  color: var(--color-darkslategray-300);
}

.divelementor-field-type-text {
  position: absolute;
  width: calc(100% - 147.76px);
  top: 0;
  right: 147.76px;
  left: 0;
  height: 45.88px;
}

.email,
.label-email {
  position: absolute;
  display: flex;
  align-items: center;
}

.label-email {
  top: -1.19px;
  left: 7.75px;
  line-height: 9.53px;
  font-weight: 600;
  width: 23.77px;
  height: 11.32px;
}

.email {
  top: 0;
  left: 0;
  width: 24.65px;
  height: 12.51px;
}

.divelementor-field-type-email {
  position: absolute;
  width: calc(100% - 147.76px);
  top: 0;
  right: 0;
  left: 147.76px;
  height: 45.88px;
}

.it-management-service,
.label-inquiry {
  position: absolute;
  display: flex;
  align-items: center;
}

.label-inquiry {
  top: -1.39px;
  left: 7.35px;
  line-height: 9.53px;
  font-weight: 600;
  width: 73.08px;
  height: 11.92px;
}

.it-management-service {
  top: 3.57px;
  left: 0;
  line-height: 19.07px;
  width: 98.29px;
  height: 11.32px;
}

.div26 {
  top: calc(50% - 9.53px);
  left: 10.13px;
  width: 109.63px;
  height: 19.07px;
  overflow: hidden;
}

.div26,
.icon4,
.options {
  position: absolute;
}

.options {
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  border-radius: 3.57px;
  background-color: var(--color-white);
  border: 0.6px solid var(--color-darkslategray-400);
  box-sizing: border-box;
  height: 28px;
}

.icon4 {
  top: 8.04px;
  left: 119.84px;
  width: 6.47px;
  height: 11.32px;
  overflow: hidden;
}

.divelementor-field {
  position: absolute;
  width: calc(100% - 15.49px);
  top: calc(50% - 5.06px);
  right: 7.75px;
  left: 7.75px;
  height: 28px;
  color: var(--color-darkslategray-300);
}

.divelementor-field-type-selec {
  position: absolute;
  width: calc(100% - 147.76px);
  top: 66.13px;
  right: 147.76px;
  left: 0;
  height: 45.88px;
}

.label-inquiry1 {
  position: absolute;
  top: -1.59px;
  left: 7.94px;
  line-height: 9.53px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 80.23px;
  height: 11.92px;
}

.divelementor-field-type-selec1 {
  position: absolute;
  width: calc(100% - 147.76px);
  top: 132.27px;
  right: 147.56px;
  left: 0.2px;
  height: 45.88px;
}

.label-phone {
  position: absolute;
  top: -1.19px;
  left: 7.75px;
  line-height: 9.53px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 27.43px;
  height: 11.32px;
}

.divelementor-field-type-tel {
  position: absolute;
  width: calc(100% - 147.76px);
  top: 66.13px;
  right: 0;
  left: 147.76px;
  height: 45.88px;
}

.label-phone1,
.location {
  position: absolute;
  display: flex;
  align-items: center;
}

.label-phone1 {
  top: -1.59px;
  left: 7.94px;
  line-height: 9.53px;
  font-weight: 600;
  width: 50.05px;
  height: 11.92px;
}

.location {
  top: 0;
  left: 0;
  width: 67.32px;
  height: 12.51px;
}

.divelementor-field-type-tel1 {
  position: absolute;
  width: calc(100% - 147.76px);
  top: 132.27px;
  right: -0.2px;
  left: 147.96px;
  height: 45.88px;
}

.label-additional,
.textarea {
  position: absolute;
  left: 7.75px;
}

.label-additional {
  top: -1.19px;
  line-height: 9.53px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 72.94px;
  height: 11.32px;
}

.textarea {
  width: calc(100% - 15.49px);
  top: calc(50% - 23.59px);
  right: 7.75px;
  border-radius: 3.57px;
  background-color: var(--color-white);
  border: 0.6px solid var(--color-darkslategray-400);
  box-sizing: border-box;
  height: 65.05px;
  overflow: hidden;
}

.divelementor-field-type-texta {
  position: absolute;
  width: 100%;
  top: 198.2px;
  right: 0;
  left: 0;
  height: 82.93px;
}

.input6 {
  position: absolute;
  top: 5.36px;
  left: 0;
  border-radius: 1.49px;
  background-color: var(--color-white);
  border: 0.6px solid #767676;
  box-sizing: border-box;
  width: 7.75px;
  height: 7.75px;
}

.label-i {
  position: absolute;
  top: 4.74px;
  left: 9.59px;
  line-height: 9.53px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 125.05px;
  height: 11.32px;
}

.spanelementor-field-option {
  position: absolute;
  top: 301.39px;
  left: 7.75px;
  width: 280.02px;
  height: 19.07px;
}

.submit-message {
  position: relative;
  letter-spacing: -0.3px;
  line-height: 9.53px;
  font-weight: 500;
}

.button3,
.form {
  position: absolute;
}

.button3 {
  bottom: -45.68px;
  left: 7.75px;
  border-radius: 8.94px;
  background-color: var(--color-darkorange);
  display: flex;
  flex-direction: row;
  padding: 13.107478141784668px 102.65538024902344px 13.107478141784668px 102.29790496826172px;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 23.831775665283203px;
  text-align: center;
  color: var(--color-white);
  font-family: var(--font-montserrat);
}

.form {
  top: 60.77px;
  left: 22.04px;
  width: 295.51px;
  height: 330.78px;
  font-size: 9.53px;
  font-family: var(--font-lato);
}

.divelementor-widget-wrap1 {
  position: absolute;
  width: calc(100% - 0.4px);
  top: 0;
  right: 0.4px;
  left: 0;
  height: 407.04px;
}

.divelementor-column-wrap {
  position: absolute;
  top: 440px;
  left: calc(50% - 170px);
  border-radius: 8.94px;
  background-color: rgba(255, 255, 255, 0.8);
  width: 340px;
  height: 468.69px;
  font-size: 16.68px;
  color: var(--color-darkslategray-100);
  font-family: var(--font-montserrat);
}

.iphone-14-pro-max-1 {
  position: absolute;
  top: 5204px;
  /* left: calc(50% - 215px); */
  /* background-color: var(--color-white); */
  /* width: 430px;
  height: 932px; */
  overflow: hidden;
  font-size: 11.94px;
  background-color: #36474f;
  width: 100%;
  /* color: var(--color-white); */
  font-family: var(--font-jost);
}

.iphone-14-pro-max-2 {
  position: absolute;
  top: 5604px;
  /* left: calc(50% - 215px); */
  /* background-color: var(--color-white); */
  /* width: 430px;
  height: 932px; */
  width: 100%;
  overflow: hidden;
  font-size: 11.94px;
  background-color: #36474f;
  /* color: var(--color-white); */
  font-family: var(--font-jost);
}

.image-7-icon {
  position: absolute;
  top: 194px;
  left: 81px;
  width: 270px;
  height: 152px;
  object-fit: cover;
}

.iphone-14-pro-max-3 {
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  height: 5670px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-mini);
  color: var(--color-darkorange);
  font-family: var(--font-montserrat);
}

.iphone-14-pro-max-3-2 {
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  height: 6000px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-mini);
  color: var(--color-darkorange);
  font-family: var(--font-montserrat);
}

.div16,
.div17 {
  position: relative;
  top: 0;
  height: 430px;
}

.div17 {
  left: 1px;
  background-color: var(--color-white);
  border: 1px solid var(--color-ghostwhite);
  box-sizing: border-box;
  width: 370px;
  border-radius: 11.12px;
}

.div16 {
  left: 0;
  background-color: transparent;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.04);
  width: 400px;
  font-size: var(--font-size-lg);
  color: #7c7d8a;
  font-family: var(--font-kumbh-sans);
}

.div17-2 {
  position: relative;
  /* top: 351px; */
  left: 0;
  background-color: var(--color-white);
  border: 1px solid var(--color-ghostwhite);
  box-sizing: border-box;
  width: 370px;
  height: 87px;
}

.div19-2 {
  position: relative;
  /* top: 0;
  left: 0; */
  line-height: 55px;
  justify-content: center;
  /* width: 57px;
  height: 57px; */
  font-size: 36px
}

.pseudo3 {
  position: absolute;
  top: -1px;
  left: 0;
  background: linear-gradient(90deg, #ff932e, #36474f);
  width: 372px;
  height: 432px;
}

.minus {
  /* background: #ff932e; */
  font-size: 36px;
  font-size: 50px;
}

.div18,
.div19,
.pseudo4 {
  position: absolute;
}

.pseudo4 {
  top: -5px;
  left: 320px;
  /* border-radius: var(--br-9xl-5); */
  /* background-color: var(--color-darkorange);
  width: 57px;
  height: 57px; */
  text-align: center;
  color: #ff932e;
  font-family: var(--font-font-awesome-5-free);
}

.pseudo6 {
  top: -20px;
  left: 320px;
  /* border-radius: var(--br-9xl-5); */
  /* background-color: var(--color-darkorange);
  width: 57px;
  height: 57px; */
  font-size: 36px;
  text-align: center;
  color: #36474f;
  font-family: var(--font-font-awesome-5-free);
}

.div18 {
  flex-direction: row;
  top: 31.5px;
  left: 1px;
  width: 370px;
  height: 24px;
  font-size: var(--font-size-xl);
  color: var(--color-darkslategray-200);
  font-family: var(--font-montserrat);
}

.pseudo5 {
  position: absolute;
  top: -16.5px;
  left: 851px;
  border-radius: var(--br-9xl-5);
  /* background-color: var(--color-darkslategray-100); */
  width: 57px;
  height: 57px;
  text-align: center;
  font-size: var(--font-size-xl);
  /* font-size: var(--font-size-sm); */
  color: var(--color-white);
  /* font-family: var(--font-font-awesome-5-free); */
}

.div19,
.is-ansoftt-pos,
.yes-absolutely-ansoftt {
  display: flex;
  align-items: center;
}

.is-ansoftt-pos {
  position: absolute;
  top: 0.31px;
  left: 22px;
  line-height: 24px;
  font-weight: 600;
  width: 300px;
  height: 24px;
}

.is-ansoftt-pos-2 {
  position: absolute;
  top: -14.31px;
  left: 20px;
  line-height: 24px;
  font-weight: 600;
  width: 275px;
  height: 24px;
  font-size: 16px;
}

.yes-absolutely-ansoftt {
  position: absolute;
  top: 200px;
  left: 22px;
  line-height: 34px;
  font-weight: 500;
  width: 340px;
  height: 90px;
}

.div20,
.div21 {
  position: relative;
  /* top: 31.5px; */
  left: 1px;
  width: 370px;
  height: 24px;
}

.spacer {
  height: 30px;
}

.spacer-10 {
  height: 10px;
}

.center {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.column {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}